import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  useUpdateNewsCategoryListDataMutation,
} from "../../../store/queries/newscategory";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/NewsCategory/newscategorySlice";
import { createNew, getDataById } from "./api";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";

const useUpdateNewsCategory = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { activeTab, currentFormData, fieldName, cropData } = useSelector(
    (state) => state.newscategory
  );

  const nameRegExp = /^[^\s].*$/;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [imagePreview, setImagePreview] = useState(currentFormData !== "" ? currentFormData?.image : "");

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImagePreview(currentFormData?.image ?? "");
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  const [updateCategory] = useUpdateNewsCategoryListDataMutation();

  let categoryVal = Yup.object({
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    image: Yup.mixed().required("Image is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: null,
      image: null,
    },
    validationSchema: categoryVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          name: values.name,
          status: +Boolean(values.status),
        };

        if (isEditData) {
          obj._id = values._id;
        }

        if (values.image?.name || values.image?.length === 0) {
          obj.image = values.image;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updateCategory(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
    setShow((state) => (state = false));
  };

  const handleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "image";
            state.cropData = {
              unit: "px",
              width: 100,
              height: 100,
              aspect: 1 / 1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus(["name"]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus(["image"]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: ["name", "status"],
    },
    {
      label: "Uploads",
      fields: ["image"],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    return (
      (fields.filter((field) => {
        const fieldValue = formik?.values[field];

         // Handle dropdown values (objects)
      if (typeof fieldValue === "object" && fieldValue !== null) {
        return Object.keys(fieldValue).length > 0; // Ensure a value is selected
      }

        return (typeof fieldValue === "string" && fieldValue.trim().length > 0) || (fieldValue instanceof File);
      }).length /
        fields.length) *
      100
    );
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
    };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            currentFormData={currentFormData}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imagePreview={imagePreview}
            handleImage={handleImage}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImagePreview={setImagePreview}
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            handleClick={handleClick}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    tabs,
    isLoading,
    activeTab,
    currentFormData,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    handleImage,
    handleTabClick,
    renderTabContent,
    imagePreview,
  };
};

export default useUpdateNewsCategory;
