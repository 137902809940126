import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/MediaNews/medianewsSlice";
import {
  useCategoryDataListQuery,
  useUpdateMediaNewsListDataMutation,
} from "../../../store/queries/medianews";
import { createNew, getDataById } from "./api";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";

const useUpdateMediaNews = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { activeTab, currentFormData, fieldName, cropData } = useSelector((state) => state.medianews);
  const profilefileInputRef = useRef(null);
  const [updateMediaNews] = useUpdateMediaNewsListDataMutation();
  const { data: basicData = {} } = useCategoryDataListQuery();
  const nameRegExp = /^[^\s].*$/;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");


  const [imageCoverPreview, setImageCoverPreview] = useState(
    currentFormData !== "" ? currentFormData?.list_thumbnail : ""
  );
  const [basicThumbnailImageCoverPreview, setBasicThumbnailImageCoverPreview] = useState(
    currentFormData !== "" ? currentFormData?.basic_thumbnail : ""
  );

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImageCoverPreview(currentFormData?.list_thumbnail ?? "");
      setBasicThumbnailImageCoverPreview(currentFormData?.basic_thumbnail ?? "");
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

 
  let medianewsVal = Yup.object({
    date: Yup.string().required("Date is Required"),
    category: Yup.mixed().required("Category is Required"),
    list_thumbnail: Yup.mixed().required("List Thumbnail is Required"),
    basic_thumbnail: Yup.mixed().required("Basic Thumbnail is Required"),
    audio: Yup.string().required("Audio is Required"),
    title: Yup.string().required("Title is Required").matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string().required("Description is Required").matches(nameRegExp, "Leading space is not allowed"),
    sub_title: Yup.string().required("Sub Title is Required").matches(nameRegExp, "Leading space is not allowed"),
  });

  let medianewsUpdateVal = Yup.object({
    date: Yup.string().required("Date is Required"),
    category: Yup.mixed().required("Category is Required"),
    title: Yup.string().required("Title is Required").matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string().required("Description is Required").matches(nameRegExp, "Leading space is not allowed"),
    sub_title: Yup.string().required("Sub Title is Required").matches(nameRegExp, "Leading space is not allowed"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      title: null,
      sub_title: null,
      description: null,
      date: null,
      category: null,
      list_thumbnail: null,
      basic_thumbnail: null,
      audio: null,
    },
    validationSchema: isEditData ? medianewsUpdateVal :medianewsVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          title: values.title,
          description: values.description,
          sub_title: values.sub_title,
          date: values.date,
          "category[id]": values.category?._id,
          "category[name]": values.category?.name,
          status: + Boolean(
            values.status
          ),
        };

        if (isEditData) {
          obj._id = values._id;
        }
        if (
          values.list_thumbnail?.name ||
          values.list_thumbnail?.length === 0
        ) {
          obj.list_thumbnail = values.list_thumbnail;
        }
        if (
          values.basic_thumbnail?.name ||
          values.basic_thumbnail?.length === 0
        ) {
          obj.basic_thumbnail = values.basic_thumbnail;
        }
        if (!isEditData && (values.audio?.[0]?.name || values.audio?.length === 0)) {
          obj.audio = values.audio?.[0];
        }
        if (
          isEditData &&
          currentFormData?.is_converted == 1 &&
          !values.audio?.[0]?.name
        ) {
          delete obj.audio;
        } else if (isEditData && currentFormData?.is_converted == 0) {
          obj.audio = "";
        } else if (isEditData && currentFormData?.is_converted == 1) {
          if (values.audio?.[0]?.name) {
            obj.audio = values.audio?.[0];
          }
        }

        let formData = new FormData();
        delete obj.category;

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        if (isEditData === true) {
          updateMediaNews(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false)
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false)
              formik.setErrors(response?.data?.errors);
            }else if (response?.status_code === 500) {
              setIsLoading(false)
              toast.error("Something Went Wrong");
            }else{
              setIsLoading(false)
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false)
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false)
              formik.setErrors(response?.errors);
            }else if (response?.status_code === 500) {
              setIsLoading(false)
              toast.error("Something Went Wrong");
            }else{
              setIsLoading(false)
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
    formik.resetForm();
    setShow((state) => (state = false));
  };
  
  const handleCoverImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "list_thumbnail";
            state.cropData = {
              unit: "px",
              width: 1600,
              height: 900,
              aspect: 16 / 9,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };
  
  const handleBasicThumbnail = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "basic_thumbnail";
            state.cropData = {
              unit: "px",
              width: 100,
              height: 100,
              aspect: 1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  // const handleSong = (e) => {
  //   formik.setFieldValue("audio", e?.target?.files);
  // };
  const handleSong = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName.slice(fileName.lastIndexOf(".") + 1);
      if (fileType.toLowerCase() === "mp3" || fileType.toLowerCase() === "wav") {
        formik.setFieldValue("audio", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file other than MP3 is selected
        // For example:
        toast.error("Please select an MP3 or WAV file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  }; 


  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "title",
        "sub_title",
        "description",
        "date",
        "category",
        // "status",
      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus([
        "list_thumbnail",
        "basic_thumbnail",
        "audio",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: ["title", "sub_title", "description", "date", "category", "status",],
    },
    {
      label: "Uploads",
      fields: ["list_thumbnail","basic_thumbnail", "audio"],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    return (
      (fields.filter((field) => {
        const fieldValue = formik?.values[field];

         // Handle dropdown values (objects)
      if (typeof fieldValue === "object" && fieldValue !== null) {
        return Object.keys(fieldValue).length > 0; // Ensure a value is selected
      }

        return (typeof fieldValue === "string" && fieldValue.trim().length > 0) || (fieldValue instanceof File);
      }).length /
        fields.length) *
      100
    );
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
    };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            categoryList={basicData?.data?.category}
            currentFormData={currentFormData}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imageCoverPreview={imageCoverPreview}
            basicThumbnailImageCoverPreview={basicThumbnailImageCoverPreview}
            handleCoverImage={handleCoverImage}
            handleBasicThumbnail={handleBasicThumbnail}
            handleSong={handleSong}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImageCoverPreview={setImageCoverPreview}
            setBasicThumbnailImageCoverPreview={setBasicThumbnailImageCoverPreview}
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            currentFormData={currentFormData}
            handleClick={handleClick}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    tabs,
    activeTab,
    isLoading,
    handleMoveToErrorTab,
    handleTabClick,
    renderTabContent,
    handleCloseModal,
    handleRemovePhotoImage,
    handleCoverImage,
    handleSong,
    imageCoverPreview,
    handleBasicThumbnail,
    setBasicThumbnailImageCoverPreview,
    profilefileInputRef,
    currentFormData,
  };
};

export default useUpdateMediaNews;
