import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteProgrammeDataMutation,
  useGetProgrammeListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/programme";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Programme/programmeSlice";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { statusChange } from "../UpdateProgramme/api";
import {
  programme as programmeQuery
} from "../../../store/queries/programme";
import { current } from "@reduxjs/toolkit";

const useProgrammeList = () => {
  const dispatch = useDispatch();
  const programmeState = useSelector((state) => state.programme);
  const { showCreateModal, activeId, selectedUpdateRows, imageData, titleImageData, listThumbnailImageData, bannerImageData, verticalImageData } = useSelector((state) => state.programme);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [programmeDelete] = useDeleteProgrammeDataMutation();
  const { isEditData } = useSelector((state) => state.programme);
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showTitleImageModal, setShowTitleImageModal] = useState(false);
  const [showListThumbnailImageModal, setShowListThumbnailImageModal] = useState(false);
  const [showBannerImageModal, setShowBannerImageModal] = useState(false);
  const [showVerticalImageModal, setShowVerticalImageModal] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  
  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: programme = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetProgrammeListDataQuery({
    length: programmeState.currentPageSize,
    page: programmeState.currentPage,
    search: programmeState.search,
    sort_by: programmeState.sortBy,
    sort_order: programmeState.sortOrder,
    status: programmeState.status,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetProgrammeListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = programme?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [programme]);

  const hasEditPermission = useMemo(() => {
    let permission = programme?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [programme]);
  const hasViewPermission = useMemo(() => {
    let permission = programme?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [programme]);
  const hasDeletePermission = useMemo(() => {
    let permission = programme?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [programme]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    description: { label: "Description", sortable: true },
    image_url: { label: "Image", sortable: true },
    title_image: { label: "Title Image", sortable: true },
    list_thumbnail: { label: "List Thumbnail", sortable: true },
    banner_image: { label: "Banner Image", sortable: true },
    vertical_thumbnail: { label: "Vertical Image", sortable: true },
    status_label: { label: "Status", sortable: false },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    dispatch(
              updateConfig((state) => {
                state.currentPageSize = 10;
              })
            );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [programmeState.status]);

  const deleteProgrammeDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (programmeState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [programmeState.clearSelection]);

  const deleteProgramme = () => {
    programmeDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (programmeState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = programmeState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = ""
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const handleProgrammeDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0]
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = programme?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };
  const handleViewTitleImage = (data) => {
    setShowTitleImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.titleImageData = data;
      })
    );
  };
  const handleViewListThumbnailImage = (data) => {
    setShowListThumbnailImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.listThumbnailImageData = data;
      })
    );
  };
  const handleViewBannerImage = (data) => {
    setShowBannerImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.bannerImageData = data;
      })
    );
  };

  const handleVerticalImage = (data) => {
    setShowVerticalImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.verticalImageData = data;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.imageData = "";
        state.titleImageData = "";
        state.listThumbnailImageData = "";
        state.bannerImageData = "";
        state.verticalImageData = "";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowImageModal(() => false);
    setShowTitleImageModal(() => false);
    setShowListThumbnailImageModal(() => false);
    setShowBannerImageModal(() => false);
    setShowVerticalImageModal(() => false)
    // dispatch(
    //   updateConfig((state) => {
    //     state.imageData = "";
    //     state.titleImageData = "";
    //   })
    // );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params);
    dispatch(
      programmeQuery.util.updateQueryData(
        "getProgrammeListData",
        {
          length: programmeState.currentPageSize,
          page: programmeState.currentPage,
          search: programmeState.search,
          sort_by: programmeState.sortBy,
          sort_order: programmeState.sortOrder,
          status: programmeState.status,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
          res?._id === data?._id
          ? {
            ...res,
            status:data?.status === 0 ? 1 : 0,
          }
          : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original:{
                ...updatedCachedData.data.original,
                data:newResult
              }
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      image_url: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        title_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewTitleImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        list_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewListThumbnailImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        banner_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewBannerImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),

        vertical_thumbnail: (feild, data) =>
          data?.[feild] ? (
            <div className="pro-nowrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Image"}`}
              onClick={() => handleVerticalImage(data?.[feild])}
            />
            </div>
          ) : (
            <>-</>
          ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    programme,
    activeId,
    programmeState,
    currentPage: programmeState.currentPage,
    activeFilter: programmeState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: programmeState.recordsTotal,
    showDeleteModal,
    // isUpdateDataSuccess,
    isEditData,
    imageData,
    showImageModal,
    titleImageData,
    listThumbnailImageData,
    bannerImageData,
    verticalImageData,
    showTitleImageModal,
    showListThumbnailImageModal,
    showBannerImageModal,
    showVerticalImageModal,
    filterShow,
    setFilterShow,
    handleCreateClick,
    deleteProgrammeDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleProgrammeDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteProgramme,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    handleClearClick,
  };
};

export default useProgrammeList;
