import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteRjDataMutation,
  useGetRjListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/rj";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Rj/rjSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import {
 rj as rjQuery
} from "../../../store/queries/rj";
import { current } from "@reduxjs/toolkit";

const useRjList = () => {
  const dispatch = useDispatch();
  const rjState = useSelector((state) => state.rj);
  const { showCreateModal, isEditData, activeId, selectedUpdateRows, imageData, titleImageData } = useSelector((state) => state.rj);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [rjDelete] = useDeleteRjDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [filterShow, setFilterShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showDetailsImageModal, setShowDetailsImageModal] = useState(false);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: rj = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetRjListDataQuery({
    length: rjState.currentPageSize,
    page: rjState.currentPage,
    search: rjState.search,
    sort_by: rjState.sortBy,
    sort_order: rjState.sortOrder,
    status: rjState.status,
    category_id: rjState.category_id,
    from_date: rjState.from_date,
    to_date: rjState.to_date,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetRjListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = rj?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [rj]);

  const hasEditPermission = useMemo(() => {
    let permission = rj?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [rj]);
  const hasViewPermission = useMemo(() => {
    let permission = rj?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [rj]);
  const hasDeletePermission = useMemo(() => {
    let permission = rj?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [rj]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    // country_code: { label: "Country code", sortable: true },
    // mobile: { label: "Mobile Number", sortable: true },
    // email: { label: "Email", sortable: true },
    // experience: { label: "Experience", sortable: true },
    // specialization: { label: "Specialization", sortable: true },
    profile_image: { label: "Profile Image", sortable: true },
    detail_image: { label: "Detailed Image", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    dispatch(
              updateConfig((state) => {
                state.currentPageSize = 10;
              })
            );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [rjState.status, rjState.from_date, rjState.to_date]);

  const deleteRjDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (rjState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [rjState.clearSelection]);

  const deleteRj = () => {
    rjDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleSort = (label) => {
    if (rjState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rjState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = ""
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };
  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleRjDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = rj?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };
  const handleViewDetailImage = (data) => {
    setShowDetailsImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.titleImageData = data;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.imageData = "";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowImageModal(() => false);
  };

  const closeDetailsModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.titleImageData = "";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowDetailsImageModal(() => false);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params);
    dispatch(
      rjQuery.util.updateQueryData(
        "getRjListData",
        {
          length: rjState.currentPageSize,
          page: rjState.currentPage,
          search: rjState.search,
          sort_by: rjState.sortBy,
          sort_order: rjState.sortOrder,
          status: rjState.status,
          category_id: rjState.category_id,
          from_date: rjState.from_date,
          to_date: rjState.to_date,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
          res?._id === data?._id
          ? {
            ...res,
            status:data?.status === 0 ? 1 : 0,
          }
          : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original:{
                ...updatedCachedData.data.original,
                data:newResult
              }
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? data[feild] : "-"}</p>
      ),
      name: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">{data[feild] ? data[feild] : "-"}</p>
      ),
      profile_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
            <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        detail_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewDetailImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    rj,
    activeId,
    rjState,
    currentPage: rjState.currentPage,
    activeFilter: rjState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: rjState.recordsTotal,
    showDeleteModal,
    imageData,
    titleImageData,
    showImageModal,
    isEditData,
    filterShow,
    setFilterShow,
    setShowImageModal,
    deleteRjDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    handleRjDataEditClick,
    handleEditModal,
    closeEditModal,
    closeModal,
    getRow,
    deleteRj,
    refetch,
    tableFields,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    showDetailsImageModal,
    closeDetailsModal
  };
};

export default useRjList;
