import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/MediaShorts/mediashortsSlice";
import {
  useDeleteMediaShortsDataMutation,
  useGetMediaShortsListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/mediashorts";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { current } from "@reduxjs/toolkit";
import { mediashorts as mediashortsQuery } from "../../../store/queries/mediashorts";

const useMediaShortsList = () => {
  const dispatch = useDispatch();
  const mediashortsState = useSelector((state) => state.mediashorts);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [mediashortsDelete] = useDeleteMediaShortsDataMutation();
  const {
    isEditData,
    activeId,
    selectedUpdateRows,
    imageData,
    basicThumbnailData,
    videoData,
    dataTitle,
    dataId,
  } = useSelector((state) => state.mediashorts);
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showBasicThumbnailModal, setShowBasicThumbnailModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [dataFileIndex, setDataFileIndex] = useState("");
  const [filterShow, setFilterShow] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    if (title) {
      const searchTitle = title.replace(/%/g, " ");
      dispatch(
        updateConfig((state) => {
          state.search = searchTitle;
        })
      );
      handleSearch(searchTitle);
    } else {
      dispatch(
        updateConfig((state) => {
          state.search = "";
        })
      );
    }
  }, []);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: mediashorts = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetMediaShortsListDataQuery({
    length: mediashortsState.currentPageSize,
    page: mediashortsState.currentPage,
    search: mediashortsState.search,
    sort_by: mediashortsState.sortBy,
    sort_order: mediashortsState.sortOrder,
    status: mediashortsState.status,
    category_id: mediashortsState.category_id,
    rj_id: mediashortsState.rj_id,
    video_id: mediashortsState.programe_id,
    from_date: mediashortsState.from_date,
    to_date: mediashortsState.to_date,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetMediaShortsListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission =
      mediashorts?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [mediashorts]);

  const hasEditPermission = useMemo(() => {
    let permission = mediashorts?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [mediashorts]);
  const hasViewPermission = useMemo(() => {
    let permission = mediashorts?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [mediashorts]);
  const hasDeletePermission = useMemo(() => {
    let permission =
      mediashorts?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [mediashorts]);

  const tableHeading = {
    title: { label: "Title", sortable: true },
    description: { label: "Description", sortable: true },
    // sub_title: { label: "Sub Title", sortable: true },
    category: { label: "Category", sortable: true },
    rj: { label: "Rj", sortable: true },
    list_thumbnail: { label: "List Thumbnail", sortable: true },
    basic_thumbnail: { label: "Basic Thumbnail", sortable: true },
    video_response: { label: "Video", sortable: true },
    duration: { label: "Duration", sortable: true },
    created_date: { label: "Created Date", sortable: true },
    updated_date: { label: "Updated Date", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    dispatch(
              updateConfig((state) => {
                state.currentPageSize = 10;
              })
            );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [
  //   mediashortsState.status,
  //   mediashortsState.category_id,
  //   mediashortsState.rj_id,
  //   mediashortsState.programe_id,
  //   mediashortsState.from_date,
  //   mediashortsState.to_date,
  // ]);

  const deleteMediashortsDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (mediashortsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [mediashortsState.clearSelection]);

  const deleteMediashorts = () => {
    mediashortsDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (mediashortsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = mediashortsState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  // const handleSearch = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.search = e.target.value;
  //       state.currentPage = 1;
  //     })
  //   );
  // };
  const handleSearch = (e) => {
    if (typeof e === "string") {
      // If 'e' is a string (title from URL), update search directly
      dispatch(
        updateConfig((state) => {
          state.search = e;
          state.currentPage = 1;
        })
      );
    } else {
      // If 'e' is an event (input change), update search from input value
      dispatch(
        updateConfig((state) => {
          state.search = e.target.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleMediashortsDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = mediashorts?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };
  const handleViewBasicThumbnailImage = (data) => {
    setShowBasicThumbnailModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.basicThumbnailData = data;
      })
    );
  };


  const handleViewVideo = (data, title, id) => {
    let dataList = mediashorts?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowVideoModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.videoData = data;
        state.dataTitle = title;
        state.dataId = id;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowImageModal(() => false);
    setShowBasicThumbnailModal(()=>false)
    dispatch(
      updateConfig((state) => {
        state.imageData = "";
        state.basicThumbnailData = "";
        state.videoData = "";
      })
    );
    setShowVideoModal(() => false);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params).then((response) => {
      if (response?.data?.status_code == 400) {
        toast.error(response?.data?.message);
      }
    });
    dispatch(
      mediashortsQuery.util.updateQueryData(
        "getMediaShortsListData",
        {
          length: mediashortsState.currentPageSize,
          page: mediashortsState.currentPage,
          search: mediashortsState.search,
          sort_by: mediashortsState.sortBy,
          sort_order: mediashortsState.sortOrder,
          status: mediashortsState.status,
          category_id: mediashortsState.category_id,
          rj_id: mediashortsState.rj_id,
          video_id: mediashortsState.programe_id,
          from_date: mediashortsState.from_date,
          to_date: mediashortsState.to_date,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? data[feild] : "-"}</p>
      ),
      rj: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">{data[feild] ? data[feild] : "-"}</p>
      ),
      category: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">{data[feild] ? data[feild] : "-"}</p>
      ),
      description: (feild, data) => (
        <p className="pro-mb-0 pro-min-lg">{data[feild] ? data[feild] : "-"}</p>
      ),
      title: (feild, data) => (
        <p className="pro-mb-0 pro-min-sm">{data[feild] ? data[feild] : "-"}</p>
      ),
      list_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        basic_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewBasicThumbnailImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      video_response: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11.8544" cy="12.0001" r="11.5372" fill="#FA6B07"/>
              <path d="M16.9648 11.1905C17.6315 11.5754 17.6315 12.5377 16.9648 12.9226L9.66422 17.1376C8.99755 17.5225 8.16421 17.0414 8.16421 16.2716L8.16421 7.84152C8.16422 7.07172 8.99755 6.59059 9.66422 6.97549L16.9648 11.1905Z" fill="white"/>
              </svg>
            }
            title={`${"Play Video"}`}
            onClick={() =>
              handleViewVideo(data?.[feild], data?.title, data?._id)
            }
          />
          </div>
        ) : (
          <>-</>
        ),
        created_date: (feild, data) => (
          <p className="pro-mb-0">
            {data[feild] ? `${data[feild]}` : "-"}
          </p>
        ),
        updated_date: (feild, data) => (
          <p className="pro-mb-0">
            {data[feild] ? `${data[feild]}` : "-"}
          </p>
        ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    if (feild === "duration") {
      return data.is_converted === 1 ? (
        rows?.[feild]?.(feild, data) ?? rows["common"](feild, data)
      ) : (
        <div className="loader" title="Processing">
          <div className="loader-inner ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    mediashorts,
    activeId,
    mediashortsState,
    currentPage: mediashortsState.currentPage,
    activeFilter: mediashortsState.currentFilter,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: mediashortsState.recordsTotal,
    showDeleteModal,
    imageData,
    showImageModal,
    basicThumbnailData,
    showBasicThumbnailModal,
    videoData,
    showVideoModal,
    // isUpdateDataSuccess,
    isEditData,
    filterShow,
    dataFileIndex,
    setDataFileIndex,
    dataTitle,
    dataId,
    setFilterShow,
    handleEditModal,
    handleCreateClick,
    deleteMediashortsDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleMediashortsDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteMediashorts,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useMediaShortsList;
