import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useDetails from "./useDetails";

const Details = ({ formik, getFieldError }) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
        // onKeyDown={(e) => {
        //   const regex = /^[A-Za-z\s]*$/;
        //   if (!regex.test(e.key)) {
        //     e.preventDefault();
        //   }
        // }}
        maxLength={32}
      />

      <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
        
        <div className="form-check form-switch  pro-mb-0">
          <input
            type="checkbox"
            className="form-check-input cursor-pointer"
            id="status"
            name="status"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? false
              );
            }}
          />
        </div>
        <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
          Status
        </label>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
