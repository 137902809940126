import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/MediaVideo/mediavideoSlice";
import {
  useDeleteMediaVideoDataMutation,
  useGetMediaVideoListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/mediavideo";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { mediavideo as mediavideoQuery } from "../../../store/queries/mediavideo";
import { current } from "@reduxjs/toolkit";

const useMediavideoList = () => {
  const dispatch = useDispatch();
  const mediavideoState = useSelector((state) => state.mediavideo);
  const {
    showCreateModal,
    isEditData,
    activeId,
    selectedUpdateRows,
    imageData,
    videoData,
    dataTitle,
    dataId,
    titleImageData,
    verticalImageData,
  } = useSelector((state) => state.mediavideo);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [mediavideoDelete] = useDeleteMediaVideoDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [filterShow, setFilterShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [dataFileIndex, setDataFileIndex] = useState("");
  const [showTitleImageModal, setShowTitleImageModal] = useState(false);
  const [showVerticalImageModal, setShowVerticalImageModal] = useState(false);
  const [showAlertModal,setShowAlertModal] = useState(false)
  const [uploadState, setUploadState] = useState({
    isUploading: false,
    progress: 0,
  });
  const closeAlertModal =()=>{
  setShowAlertModal(false)
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    if (title) {
      const searchTitle = title.replace(/%/g, " ");
      dispatch(
        updateConfig((state) => {
          state.search = searchTitle;
        })
      );
      handleSearch(searchTitle);
    } else {
      dispatch(
        updateConfig((state) => {
          state.search = "";
        })
      );
    }
  }, []);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: mediavideo = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetMediaVideoListDataQuery({
    length: mediavideoState.currentPageSize,
    page: mediavideoState.currentPage,
    search: mediavideoState.search,
    sort_by: mediavideoState.sortBy,
    sort_order: mediavideoState.sortOrder,
    status: mediavideoState.status,
    category_id: mediavideoState.category_id,
    rj_id: mediavideoState.rj_id,
    type: mediavideoState.type,
    programe_id: mediavideoState.programe_id,
    from_date: mediavideoState.from_date,
    to_date: mediavideoState.to_date,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetMediaVideoListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = mediavideo?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [mediavideo]);

  const hasEditPermission = useMemo(() => {
    let permission = mediavideo?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [mediavideo]);
  const hasViewPermission = useMemo(() => {
    let permission = mediavideo?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [mediavideo]);
  const hasDeletePermission = useMemo(() => {
    let permission = mediavideo?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [mediavideo]);

  const tableHeading = {
    title: { label: "Title", sortable: true },
    description: { label: "Description", sortable: true },
    program_name: { label: "Program", sortable: true },
    type: { label: "Type", sortable: true },
    episode_number: {label: "Episode", sortable: true},
    duration: { label: "Duration", sortable: true },
    category: { label: "Category", sortable: true },
    rj: { label: "Rj", sortable: true },
    title_image: { label: "Title Image", sortable: true },
    banner_image: { label: "Banner Image", sortable: true },
    list_thumbnail: { label: "List Thumbnail", sortable: true },
    basic_thumbnail: { label: "Basic Thumbnail", sortable: true },
    vertical_thumbnail: { label: "Vertical Thumbnail", sortable: true },
    video_response: { label: "Video", sortable: true },
    created_date: { label: "Created Date", sortable: true },
    updated_date: { label: "Updated Date", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    dispatch(
              updateConfig((state) => {
                state.currentPageSize = 10;
              })
            );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let pollingInterval;

    const checkConversion = () => {
      const allConverted = mediavideo?.data?.original?.data?.some(item => item?.is_converted !== 1);
      if (!allConverted) {
        clearInterval(pollingInterval); // Stop polling
      } else {
        refetch(); // Fetch data again if not all converted
      }
    };

    // Start polling every 1 minute
    pollingInterval = setInterval(checkConversion, 60000);

    // Run immediately on mount
    checkConversion();

    // Cleanup polling on unmount
    return () => {
      clearInterval(pollingInterval);
    };
  }, [mediavideo, refetch]);


  const deleteMediavideoDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (mediavideoState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [mediavideoState.clearSelection]);

  const deleteMediavideo = () => {
    mediavideoDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (mediavideoState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = mediavideoState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  // const handleSearch = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.search = e.target.value;
  //       state.currentPage = 1;
  //     })
  //   );
  // };
  const handleSearch = (e) => {
    if (typeof e === "string") {
      // If 'e' is a string (title from URL), update search directly
      dispatch(
        updateConfig((state) => {
          state.search = e;
          state.currentPage = 1;
        })
      );
    } else {
      // If 'e' is an event (input change), update search from input value
      dispatch(
        updateConfig((state) => {
          state.search = e.target.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleMediavideoDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = mediavideo?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };
  const handleViewTitleImage = (data) => {
    setShowTitleImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.titleImageData = data;
      })
    );
  };

  const handleVerticalImage = (data) => {
    setShowVerticalImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.verticalImageData = data;
      })
    );
  };

  const handleViewVideo = (data, title, id) => {
    let dataList = mediavideo?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowVideoModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.videoData = data;
        state.dataTitle = title;
        state.dataId = id;
      })
    );
  };

  const closeModal = () => {
    if (uploadState && uploadState?.isUploading) {
    setShowAlertModal(true)
  }else{
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowImageModal(() => false);
    dispatch(
      updateConfig((state) => {
        state.imageData = "";
        state.videoData = "";
        state.titleImageData = "";
        state.verticalImageData = "";
      })
    );
    setShowVideoModal(() => false);
    setShowTitleImageModal(() => false);
    setShowVerticalImageModal(() => false)
  }
  };

  const sumbitAlert =()=>{
  setShowAlertModal(false)
  dispatch(
    updateConfig((state) => {
      state.showCreateModal = false;
    })
  );
  }

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params).then((response) => {
      if (response?.data?.status_code == 400) {
        toast.error(response?.data?.message);
      }
    });
    dispatch(
      mediavideoQuery.util.updateQueryData(
        "getMediaVideoListData",
        {
          length: mediavideoState.currentPageSize,
          page: mediavideoState.currentPage,
          search: mediavideoState.search,
          sort_by: mediavideoState.sortBy,
          sort_order: mediavideoState.sortOrder,
          status: mediavideoState.status,
          category_id: mediavideoState.category_id,
          rj_id: mediavideoState.rj_id,
          type: mediavideoState.type,
          programe_id: mediavideoState.programe_id,
          from_date: mediavideoState.from_date,
          to_date: mediavideoState.to_date,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      basic_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
            <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
            />
          </div>
        ) : (
          <>-</>
        ),
      list_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      banner_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      title_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewTitleImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        vertical_thumbnail: (feild, data) =>
          data?.[feild] ? (
            <div className="pro-nowrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Image"}`}
              onClick={() => handleVerticalImage(data?.[feild])}
            />
            </div>
          ) : (
            <>-</>
          ),
      video_response: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11.8544" cy="12.0001" r="11.5372" fill="#FA6B07"/>
              <path d="M16.9648 11.1905C17.6315 11.5754 17.6315 12.5377 16.9648 12.9226L9.66422 17.1376C8.99755 17.5225 8.16421 17.0414 8.16421 16.2716L8.16421 7.84152C8.16422 7.07172 8.99755 6.59059 9.66422 6.97549L16.9648 11.1905Z" fill="white"/>
              </svg>
            }
            title={`${"Play Video"}`}
            onClick={() =>
              handleViewVideo(data?.[feild], data?.title, data?._id)
            }
          />
          </div>
        ) : (
          <>-</>
        ),
      type: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">
          {data[feild]?.name ? `${data[feild]?.name}` : "-"}
        </p>
      ),
      rj: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      program_name: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      title: (feild, data) => (
        <p className="pro-mb-0 pro-min-sm">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      description: (feild, data) => (
        <p className="pro-mb-0 pro-min-lg">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      created_date: (feild, data) => (
        <p className="pro-mb-0">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      updated_date: (feild, data) => (
        <p className="pro-mb-0">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;

        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    if (feild === "duration") {
      return data.is_converted === 1 ? (
        rows?.[feild]?.(feild, data) ?? rows["common"](feild, data)
      ) : (
        <div className="loader" title="Processing">
          <div className="loader-inner ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    mediavideo,
    activeId,
    mediavideoState,
    currentPage: mediavideoState.currentPage,
    activeFilter: mediavideoState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: mediavideoState.recordsTotal,
    showDeleteModal,
    imageData,
    showImageModal,
    videoData,
    showVideoModal,
    // isUpdateDataSuccess,
    isEditData,
    filterShow,
    dataFileIndex,
    setDataFileIndex,
    dataTitle,
    dataId,
    titleImageData,
    verticalImageData,
    showTitleImageModal,
    showVerticalImageModal,
    setFilterShow,
    handleEditModal,
    handleCreateClick,
    deleteMediavideoDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleMediavideoDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteMediavideo,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    uploadState, 
    setUploadState,
    showAlertModal,
    closeAlertModal,
    sumbitAlert,
  };
};

export default useMediavideoList;
