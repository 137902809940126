import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useDetails from "./useDetails";
import Select from "react-select";

const Details = ({ formik, getFieldError, typeList, maxLength, handleMalayalamTextChange, handleTextChange }) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name (Max 30 Char)*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
        maxLength={30}
      />
      <div className="input-wrap pro-mb-4">
        <div className="pro-d-flex pro-justify-between pro-mb-1 pro-w-100">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description*
          </label>
          <span className="pro-font-xs pro-opacity-60">
            {formik.values.description !== null ? (
              <>
                {formik.values.description?.length}/{maxLength}
              </>
            ) : (
              <>
                {0}/{maxLength}
              </>
            )}
          </span>
        </div>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
          value={formik.values.description}
          onChange={handleTextChange}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="pro-d-flex pro-justify-between pro-mb-1 pro-w-100">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description in Malayalam
          </label>
          <span className="pro-font-xs pro-opacity-60">
            {formik.values.description_mal !== null ? (
              <>
                {formik.values.description_mal?.length}/{maxLength}
              </>
            ) : (
              <>
                {0}/{maxLength}
              </>
            )}
          </span>
        </div>
        <textarea
          id="description_mal"
          rows={4}
          name="description_mal"
          className={`pro-input lg ${
            formik.touched.description_mal && formik.errors.description_mal && " error"
          }`}
          {...formik.getFieldProps("description_mal")}
          value={formik.values.description_mal}
          onChange={handleMalayalamTextChange}
        ></textarea>
        {formik.touched.description_mal && formik.errors.description_mal && (
          <span className="error-text">{formik.errors.description_mal}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type*
        </label>
        <Select
          id="program_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${
            getFieldError("program_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={typeList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={typeList?.filter((m) => formik.values.program_type?.id === m?.id)}
          onBlur={formik.handleBlur("program_type")}
          onChange={(value) => {
            formik.setFieldValue("program_type", value || null);
          }}
        />
        {getFieldError("program_type") && (
          <span className="error-text">{getFieldError("program_type")}</span>
        )}
      </div>
      <div className="row">
        <div className="col-auto">
          <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
            <div className="form-check form-switch pro-mb-0  ">
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                id="status"
                name="status"
                checked={Boolean(formik.values?.status) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "status",
                    !Boolean(formik.values?.status) ?? false
                  );
                }}
              />
            </div>
            <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
              Status
            </label>
          </div>
        </div>

        {/* <div className="col-auto">
          <div className="input-wrap pro-mb-4  pro-flex-row pro-items-center">
            <div className="form-check form-switch pro-mb-0">
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                id="status"
                name="status"
                checked={Boolean(formik.values?.show_on_banner) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "show_on_banner",
                    !Boolean(formik.values?.show_on_banner) ?? false
                  );
                }}
              />
            </div>
            <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
              Banner
            </label>
          </div>
        </div> */}
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
