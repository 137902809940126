import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/MediaShorts/mediashortsSlice";
import {
  useCategoryDataListQuery,
  useUpdateMediaShortsListDataMutation,
} from "../../../store/queries/mediashorts";
import { createNew, getDataById } from "./api";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";
import { contentRatingList } from "../../Podcast/updatePodcast/api";

const useUpdateMediaShorts = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const profilefileInputRef = useRef(null);
  const { activeTab, currentFormData, fieldName, trimData, cropData } = useSelector((state) => state.mediashorts);
  const { data: basicData = {} } = useCategoryDataListQuery();
  const nameRegExp = /^[^\s].*$/;
  const maxLength = 300;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [formikVideo, setFormikVideo] = useState("");
  const [ratingList, setRatingList] = useState([]);

  const [imageThumbnailPreview, setImageThumbnailPreview] = useState(
    currentFormData !== "" ? currentFormData?.thumbnail : ""
  );
  const [basicThumbnailimageCoverPreview, setBasicThumbnailImageCoverPreview] =
    useState(currentFormData !== "" ? currentFormData?.basic_thumbnail : "");

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImageThumbnailPreview(currentFormData?.thumbnail ?? "");
      setBasicThumbnailImageCoverPreview(
        currentFormData?.basic_thumbnail ?? ""
      );
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  useEffect(() => {
    contentRatingList().then((response) => {
      setRatingList(response?.data?.data);
    });
  }, []);

  const defaultRating = ratingList.find((item) => item.code === "unrated");
  const initialContentRating = defaultRating ? [defaultRating] : [];

  const [updateMediaShorts] = useUpdateMediaShortsListDataMutation();

  let mediashortsVal = Yup.object({
    category: Yup.mixed().required("Category is Required"),
    rj: Yup.mixed().required("Rj is Required"),
    content_rating: Yup.mixed().required("Content Rating is Required"),
    thumbnail: Yup.string().required("Thumbnail is Required"),
    video: Yup.string().required("Video is Required"),
    title: Yup.mixed().required("Title is Required"),
    description: Yup.mixed().required("Description is Required"),
    // sub_title: Yup.mixed().required("*Required"),
    // video_id: Yup.mixed().required("Please Select a Video"),
    basic_thumbnail: Yup.mixed().required("Basic Thumbnail is Required"),
  });

  let mediashortsUpdateVal = Yup.object({
    category: Yup.mixed().required("Category is Required"),
    rj: Yup.mixed().required("Rj is Required"),
    content_rating: Yup.mixed().required("Content Rating is Required"),
    title: Yup.mixed().required("Title is Required"),
    description: Yup.mixed().required("Description is Required"),
    // sub_title: Yup.mixed().required("*Required"),
    // video_id: Yup.mixed().required("Please Select a Video"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      title: null,
      description: null,
      // sub_title: null,
      category: null,
      rj: null,
      content_rating: null,
      video_id: null,
      thumbnail: null,
      basic_thumbnail: null,
      video: null,
    },
    validationSchema: isEditData ? mediashortsUpdateVal : mediashortsVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      
      setIsLoading(true);
      try {
        let obj = {
          title: values.title,
          description: values.description,
          // sub_title: values.sub_title,
          "category[id]": values.category?._id,
          "category[name]": values.category?.name,
          "rj[id]": values.rj?._id,
          "rj[name]": values.rj?.name,
          "content_rating[0][name]": isEditData ? values.content_rating?.[0]?.name || values.content_rating?.name : values.content_rating?.name,
          "content_rating[0][code]": isEditData ? values.content_rating?.[0]?.code || values.content_rating?.code : values.content_rating?.code,
          // video_id: values.video_id ? values.video_id?.id : "",
          status: +Boolean(values.status),
        };

        if (isEditData) {
          obj._id = values._id;
          obj.video_id = values.video_id;
        }
        if (values.thumbnail?.name || values.thumbnail?.length === 0) {
          obj.thumbnail = values.thumbnail;
        }
        if (
          values.basic_thumbnail?.name ||
          values.basic_thumbnail?.length === 0
        ) {
          obj.basic_thumbnail = values.basic_thumbnail;
        }
        if (!isEditData && (values.video?.[0]?.name || values.video?.length === 0)) {
          obj.video = values.video?.[0];
        }
        if (
          isEditData &&
          currentFormData?.is_converted == 1 &&
          !values.video?.[0]?.name
        ) {
          delete obj.video;
        } else if (isEditData && currentFormData?.is_converted == 0) {
          obj.video = "";
        } else if (isEditData && currentFormData?.is_converted == 1) {
          if (values.video?.[0]?.name) {
            obj.video = values.video?.[0];
          }
        }
        if (values.video_id) {
          obj.video_id = values.video_id?.id ? values.video_id?.id : values.video_id;
        }else{
          obj.video_id =""
        }
        // if (values.content_rating) {
        //   values.content_rating.forEach((rating, index) => {
        //     obj[`content_rating[${index}][name]`] = rating.name;
        //     obj[`content_rating[${index}][code]`] = rating.code;
        //   });
        // }
        let formData = new FormData();
        delete obj.category;
        delete obj.rj;
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        if (isEditData === true) {
          updateMediaShorts(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });
  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };
  
  
  useEffect(() => {
    if (isEditData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
    setShow((state) => (state = false));
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "thumbnail";
            state.cropData = {
              unit: "px",
              width: 900,
              height: 1600,
              aspect: 9 / 16,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleBasicThumbnailCoverImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "basic_thumbnail";
            state.cropData = {
              unit: "px",
              width: 100,
              height: 100,
              aspect: 1 / 1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };
  // const handlevideo = (e) => {
  //   formik.setFieldValue("video", e?.target?.files);
  //   // setShow(true);
  //   // dispatch(
  //   //   updateConfig((state) => {
  //   //     state.trimData = {
  //   //       min: 0,
  //   //       max: 15 || 60,
  //   //     };
  //   //   })
  //   // );
  //   // setFormikVideo( e.target.files)
  // };
  const handlevideo = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();
      const allowedExtensions = ["mp4", "ogx", "oga", "ogv", "ogg", "webm", "mkv", "mov"];
      if (allowedExtensions.includes(fileType)) {
        formik.setFieldValue("video", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file with an invalid extension is selected
        // For example:
        toast.error("Please select a valid video file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  };

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "title",
        "description",
        // "sub_title",
        "category",
        "rj",
        "video_id",
        // "status",
      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus([
        "thumbnail",
        "basic_thumbnail",
        "video",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: [
        "title",
        "description",
        "sub_title",
        "category",
        "rj",
        "video_id",
        "status",
      ],
    },
    {
      label: "Uploads",
      fields: ["thumbnail", 
      "basic_thumbnail",
       "video"],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    return (
      (fields.filter((field) => {
        const fieldValue = formik?.values[field];

         // Handle dropdown values (objects)
      if (typeof fieldValue === "object" && fieldValue !== null) {
        return Object.keys(fieldValue).length > 0; // Ensure a value is selected
      }

        return (typeof fieldValue === "string" && fieldValue.trim().length > 0) || (fieldValue instanceof File);
      }).length /
        fields.length) *
      100
    );
  }
  
  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
    };
    const handleTextChange = (e) => {
      const newText = e.target.value;
      if (newText?.length <= 300) {
        formik.setFieldValue("description", newText);
      }
    };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            categoryList={basicData?.data?.category}
            rjList={basicData?.data?.rjs}
            ratingList={ratingList}
            shortsType={basicData?.data?.videos}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            currentFormData={currentFormData}
            maxLength={maxLength}
            handleTextChange={handleTextChange}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imageThumbnailPreview={imageThumbnailPreview}
            basicThumbnailimageCoverPreview={basicThumbnailimageCoverPreview}
            handleCoverImage={handleCoverImage}
            handleBasicThumbnailCoverImage={handleBasicThumbnailCoverImage}
            handlevideo={handlevideo}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImageThumbnailPreview={setImageThumbnailPreview}
            setBasicThumbnailImageCoverPreview={setBasicThumbnailImageCoverPreview}
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            formikVideo={formikVideo}
            setFormikVideo={setFormikVideo}
            trimData={trimData}
            currentFormData={currentFormData}
            handleClick={handleClick}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    tabs,
    activeTab,
    isLoading,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    renderTabContent,
    handleTabClick,
    profilefileInputRef,
    currentFormData,
  };
};

export default useUpdateMediaShorts;
