import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UserFilterForm from "./UserFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/Users/usersSlice";

const useFilter = ({setShowform,setIsFilterApplied,setNewTotal}) => {

  const dispatch = useDispatch();
  const state = useSelector((state) => state.users);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    from_date: state.from_date,
    to_date: state.to_date,
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { status, from_date,
        to_date, 
      } = values;
      if (
        status === "" &&
        from_date === "" &&
        to_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
        setIsFilterApplied(false)
      }
      let data = {
        status,
        from_date,
        to_date,
      };
      if (values) {
        setIsFilterApplied(true)
        dispatch(setStoreFormValues(values))
        setNewTotal({})
      }
    },
  });
  // useEffect(() => {
  //   // Check if status is not empty before refetching
  //   if (!filterShow && formik.values.status !== '') {
  //     dispatch(setStoreFormValues(formik.values.status))
  //   }
  // }, [formik.values.status]);

  const renderTabContent = () => {
    return (
      <UserFilterForm
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
