import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useDetails from "./useDetails";
import Select from "react-select";
import { useEffect, useMemo, useState } from "react";
import { getSearchData } from "../api";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/MediaShorts/mediashortsSlice";
import { useDispatch } from "react-redux";

const Details = ({
  isEditData,
  formik,
  categoryList,
  rjList,
  getFieldError,
  currentFormData,
  maxLength,
  handleTextChange,
  ratingList,
}) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  const { shortsList } = useSelector((state) => state.mediashorts);
  const [searchShorts, setSearchShorts] = useState("");
  const dispatch = useDispatch();

  const handleInputChange = (item) => {
    if (item?.length > 2) {
      getSearchData(item).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.shortsList = response?.data?.data;
          })
        );
      });
    } else if (item?.length === 0) {
      getSearchData("").then((response) => {
        dispatch(
          updateConfig((state) => {
            state.shortsList = response?.data?.data;
          })
        );
      });
    }
  };

  const handleShorts = (value) => {
    formik.setFieldValue("video_id", value || null);
    setSearchShorts(value?.id);
    dispatch(
      updateConfig((state) => {
        state.selectedShortsId = value?.id;
      })
    );
  };

  const noOptionsMessage = () =>
    shortsList?.length > 0 ? "No matching options" : "Search for shorts...";

  const useDataOptions = useMemo(
    () =>
      shortsList?.map((opt) => {
        return { value: opt?.title, label: opt?.title, id: opt?._id };
      }),
    [shortsList]
  );

  useEffect(() => {
    getSearchData("").then((response) => {
      dispatch(
        updateConfig((state) => {
          state.shortsList = response?.data?.data;
        })
      );
    });
    //eslint-disable-next-line
  }, [formik.values.video_id === null]);

  useEffect(() => {
    if (isEditData) {
      setSearchShorts(
        formik.values?.video_id?._id
          ? formik.values?.video_id?._id
          : formik.values?.video_id?.id
      );
      dispatch(
        updateConfig((state) => {
          state.shortsList = [
            {
              title: formik.values?.video_id?.value,
              _id: formik.values?.video_id?.id,
            },
          ];
        })
      );
    } else {
      setSearchShorts(
        formik.values?.video_id?._id
          ? formik.values?.video_id?._id
          : formik.values?.video_id?.id
      );
    }
  }, [isEditData, formik.values.video_id]);

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Title (Max 60 Char)*"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
        maxLength={60}
      />

      <div className="input-wrap pro-mb-4">
      <div className="pro-d-flex pro-justify-between pro-mb-1 pro-w-100">
        <label htmlFor="" className="pro-font-sm pro-fw-medium">
          Description*
        </label>
          <span className="pro-font-xs pro-opacity-60">
            {formik.values.description !== null ? <>{formik.values.description?.length}/{maxLength}</> : <>{0}/{maxLength}</>}
          </span>
      </div>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
          value={formik.values.description}
          onChange={handleTextChange}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      {/* <Input
        label={"Sub Title"}
        type="text"
        id="sub_title"
        name="sub_title"
        className={`pro-input lg ${getFieldError("sub_title") && " error"}`}
        {...formik.getFieldProps("sub_title")}
        error={getFieldError("sub_title")}
        errorMessage={getFieldError("sub_title")}
      /> */}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Category*
        </label>
        <Select
          id="category"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("category") && " error"}`}
          classNamePrefix="pro-input"
          options={categoryList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={categoryList?.filter(
            (m) => formik.values.category?._id === m?._id
          )}
          onBlur={formik.handleBlur("category")}
          onChange={(value) => {
            formik.setFieldValue("category", value || null);
          }}
        />
        {getFieldError("category") && (
          <span className="error-text">{getFieldError("category")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Rj*
        </label>
        <Select
          id="rj"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("rj") && " error"}`}
          classNamePrefix="pro-input"
          options={rjList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={rjList?.filter((m) => formik.values.rj?._id === m?._id)}
          onBlur={formik.handleBlur("rj")}
          onChange={(value) => {
            formik.setFieldValue("rj", value || null);
          }}
        />
        {getFieldError("rj") && (
          <span className="error-text">{getFieldError("rj")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Content Rating*
        </label>
        <Select
          id="content_rating"
          placeholder={"Select"}
          className={`pro-input lg ${
            getFieldError("content_rating") && " error"
          }`}
          classNamePrefix="pro-input"
          // isMulti
          options={ratingList}
          getOptionValue={(option) => option?.code}
          getOptionLabel={(option) => option?.name}
          // value={formik.values.content_rating?.map((selectedOption) => ({
          //   code: selectedOption.code,
          //   name: selectedOption.name,
          // }))}
          // onChange={(value) => {
          //   formik.setFieldValue(
          //     "content_rating",
          //     // Map selected option values to their corresponding objects from ratingList
          //     value.map((selectedOption) =>
          //       ratingList.find((option) => option.code === selectedOption.code)
          //     )
          //   );
          //  }}
          value={formik.values.content_rating}
          onChange={(selectedOption) => {
            formik.setFieldValue("content_rating", selectedOption);
          }}
          onBlur={formik.handleBlur("content_rating")}
        />

        {getFieldError("content_rating") && (
          <span className="error-text">{getFieldError("content_rating")}</span>
        )}
      </div>


      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Videos
        </label>
        <Select
          id="video_id"
          placeholder="Search for videos..."
          name="video_id"
          noOptionsMessage={noOptionsMessage}
          isClearable={formik.values?.video_id}
          options={useDataOptions}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={useDataOptions?.find((item) => item?.id === (searchShorts ?? currentFormData?.video_id))}
          onInputChange={(value) => handleInputChange(value)}
          className={`pro-input lg ${getFieldError("video_id") && " error"}`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("video_id")}
          onChange={(value) => handleShorts(value)}
        ></Select>
        {getFieldError("video_id") && (
          <span className="error-text">{getFieldError("video_id")}</span>
        )}
      </div>

      {isEditData && currentFormData?.is_converted === 1 ? (
        <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
          <div className="form-check form-switch pro-mb-0">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              id="status"
              name="status"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? false
                );
              }}
            />
          </div>
          <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
            Status
          </label>
        </div>
      ) : (
        ""
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-mx-3"} onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
