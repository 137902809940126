import DashboardHero from "../DashboardHero";
import useHomeDashboardListing from "./useHomeDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";
import {
  Button,
  DataContainer,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import Charts from "../../Global/Charts";
import { Link } from "react-router-dom";
import BarChart from "../BarChart";
import TopPerformance from "../TopPerformance";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import TopRjList from "../TopRjs";
import TopProgramList from "../TopPrograms";
import TopPlayedList from "../TopPlayed";
import { Doughnut } from "react-chartjs-2";
import TopGenresList from "../TopGenres";
import DashboardCardShimmer from "../../Global/DashboardCardShimmer";
import { Tooltip } from "react-tooltip";
import { Tooltip as BootstrapTooltip, OverlayTrigger } from "react-bootstrap";
import { useMemo } from "react";
import DashboardCard from "../DashboardCard";

const HomeDashboardListing = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    children,
    listingType,
    doughnutData,
    genderGraph,
    doughnutChartData,
    doughnutChartOptions,
    doughnutTextCenter,
    semiDoughnutTextCenter,
    barData,
    barDataOptions,
    tabNavs,
    filteredTotals,
    ageBarData,
    ageBarDataOptions,
    lineData,
    lineOptions,
    deviceDoughnutData,
    deviceDoughnutOption,
    handleDateRangeChange,
    date,
    activeTab,
    ExportData,
    barDataCities,
    barDataCitiesOptions,
    fireBasedData,
    isFireBasedDataFetching,
    lineChartDataNew,
    menuState,
    appAnalyticsDashboardData,
    // appAnalyticsFirebaseData,
    // isFetchingAppAnalyticsFirebaseData,
    lineChartDataOptions,
    chartDataNew,
    isSuccess,
    combinedData,
  } = useHomeDashboardListing();

  const mediaTypes = ["videos", "podcasts", "shorts"];
  const baseTypes = [
    "average_engagement_time",
    "deleted_users",
    "download_count",
  ];

  return (
    <>
      <div className="row pro-justify-end ">
        <div className="col">
          <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
        </div>
        {activeTab !== "app_analytics" && (
          <div className="col-auto">
            <Button
              className="pro-btn-primary pro-lh-base"
              onClick={()=>ExportData(combinedData,filteredTotals)}
            >
              Export
            </Button>
          </div>
        )}

        <div className="col-auto">
          <CustomDateRangePicker
            defaultDateRange={date}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
      </div>

      <div className={`pro-w-100 pro-my-5`}>
        <div className={`row g-2 card_listing ${activeTab === "app_analytics" ? "row-cols-5" : "row-cols-4"}`}>
          <DashboardHero
            data={
              activeTab === "app_analytics"
                ? appAnalyticsDashboardData?.data?.analytic_data
                : filteredTotals
            }
            obj={dashboardItems}
            loading={isSuccess}
            listingType={menuState?.data_type ?? ""}
            shimmerCount={activeTab === "app_analytics" ? 4 : 4}
            lineChartDataNew={lineChartDataNew}
            activeTab={activeTab}
          />
          {/* {activeTab !== "app_analytics" && (
            !isFetching ? (
              <div className={`col ${Style.card_col}`}>
                <DataContainer>
                  {dashboardData?.data?.totals && (
                    <>
                      <div
                        className={`${Style.media_count_status} pro-d-flex pro-items-center  pro-gap-4`}
                      >
                        {mediaTypes.map((type) => (
                          <div
                            key={type}
                            className="col pro-d-flex pro-flex-column pro-justify-between "
                          >
                            <div className={Style.top_wrap}>
                              <OverlayTrigger
                                placement="auto-end"
                                // delay={{ show: 250, hide: 5000 }}
                                overlay={
                                  <BootstrapTooltip className="custom-tooltip">
                                    <strong>{dashboardData?.data?.totals?.[type]?.label}:</strong>
                                    <p>{dashboardData?.data?.totals?.[type]?.tool_tip || ""}</p>
                                  </BootstrapTooltip>
                                }
                              >
                                <span
                                  className={Style.inline_wrap}
                                >
                                  <h3
                                    className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                                  >
                                    {
                                      dashboardData?.data?.totals?.[type]?.current
                                    }
                                  </h3>

                                  <h6
                                    className={`${Style.category} pro-ttl  h6 pro-mb-0 pro-fw-medium`}
                                  >
                                    {dashboardData?.data?.totals?.[type]?.label}
                                  </h6>
                                </span>
                              </OverlayTrigger>
                            </div>
                            <div
                              className={`${Style.bottom_wrap} pro-pt-4 pro-mt-auto`}
                            >
                              <span className={Style.total}>
                                Total -{" "}
                                <strong>
                                  {dashboardData?.data?.totals?.[type]?.total}
                                </strong>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </DataContainer>
              </div>
            ) : (
              <div className={`col ${Style.card_col}`}>
                <DashboardCardShimmer />
              </div>
            )
          )} */}
          {activeTab === "app_analytics" && (
            // !isFetching ? (
              isSuccess ?(
              <div className={`col flex-fill ${Style.card_col}`}>
                <DataContainer>
                  {appAnalyticsDashboardData?.data?.media_engagement_duration && (
                    <>
                      <div
                        className={`${Style.media_count_status} pro-d-flex pro-items-center  pro-gap-4`}
                      >
                        {appAnalyticsDashboardData?.data?.media_engagement_duration.map((type) => (
                          <div
                            key={type}
                            className="col pro-d-flex pro-flex-column pro-justify-between "
                          >
                            <div className={Style.top_wrap}>
                              <OverlayTrigger
                                placement="auto-end"
                                // delay={{ show: 250, hide: 5000 }}
                                overlay={
                                  <BootstrapTooltip className="custom-tooltip">
                                    <strong>{type?.label}:</strong>
                                    <p>{type?.tool_tip || ""}</p>
                                  </BootstrapTooltip>
                                }
                              >
                                <span
                                  className={Style.inline_wrap}
                                >
                                  <h3
                                    className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                                  >
                                    {
                                      type?.current
                                    }
                                  </h3>

                                  <h6
                                    className={`${Style.category} pro-ttl  h6 pro-mb-0 pro-fw-medium`}
                                  >
                                    {type?.label}
                                  </h6>
                                </span>
                              </OverlayTrigger>
                            </div>
                            <div
                              className={`${Style.bottom_wrap} pro-pt-4 pro-mt-auto`}
                            >
                              <span className={Style.total}>
                                Previous -{" "}
                                <strong>
                                  {type?.previous}
                                </strong>
                              </span>
                              <span className={Style.total}>
                                Total Sessions -{" "}
                                <strong>
                                  {type?.total_users}
                                </strong>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </DataContainer>
              </div>
            ) : (
              <div className={`col flex-fill ${Style.card_col}`}>
                <DashboardCardShimmer />
              </div>
            )
          )
          }
        </div>

        <div className="row gx-2 pro-justify-between pro-mt-5">
          {/* {activeTab !== "app_analytics" && (
              <>
                {!isFireBasedDataFetching ? (
                  <div className={`col-6 ${Style.card_col}`}>
                    <DataContainer>
                      {fireBasedData?.data && (
                        <>
                          <div
                            className={`${Style.media_count_status} pro-d-flex pro-items-center  pro-gap-4`}
                          >
                            {baseTypes.map((type) => (
                              <div
                                key={type}
                                className="col pro-d-flex pro-flex-column pro-justify-between "
                              >
                                <div className={Style.top_wrap}>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <BootstrapTooltip hidden={!fireBasedData?.data?.[type]?.original_value} className="custom-tooltip">
                                      <strong>{fireBasedData?.data?.[type]?.label}: </strong>
                                      <p>{fireBasedData?.data?.[type]?.original_value}</p>
                                    </BootstrapTooltip>
                                  }
                                >
                                  <div>
                                  <span
                                    className={Style.inline_wrap}
                                  >
                                    <h3
                                      className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                                    >
                                      {fireBasedData?.data?.[type]?.value}
                                    </h3>
                                  </span>
                                  <span className={Style.total}>
                                    {fireBasedData?.data?.[type]?.label}
                                  </span>
                                  </div>
                                </OverlayTrigger>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </DataContainer>
                  </div>
                ) : (
                  <div className={`col-6 ${Style.card_col}`}>
                    <DashboardCardShimmer />
                  </div>
                )}
              </>
            )} */}
          {activeTab === "app_analytics" && (
            <div className="col-10">
              <DataContainer>
                <div className="pro-d-flex pro-gap-2 pro-justify-between pro-items-center pro-mb-3">
                  <h6 className="pro-ttl h6 pro-mb-0">{chartDataNew?.data?.data?.labels}</h6>
                  <ul className="pro-d-flex pro-gap-5 pro-mb-3">
                    <li
                      className={`${Style.tab_btn} ${menuState?.filter_type === 1 && Style.active
                        }`}
                      onClick={() => {
                        lineChartDataNew(1, menuState?.data_type);
                      }}
                    >
                      Day
                    </li>
                    <li
                      className={`${Style.tab_btn} ${menuState?.filter_type === 2 && Style.active
                        }`}
                      onClick={() => {
                        lineChartDataNew(2, menuState?.data_type);
                      }}
                    >
                      Month
                    </li>
                    <li
                      className={`${Style.tab_btn} ${menuState?.filter_type === 3 && Style.active
                        }`}
                      onClick={() => {
                        lineChartDataNew(3, menuState?.data_type);
                      }}
                    >
                      Year
                    </li>
                  </ul>
                </div>
                <div className={`${Style.curve_graph} pro-d-flex`}>

                  <Charts
                    type="bar"
                    labels={lineChartDataOptions?.labels}
                    datasets={lineChartDataOptions?.datasets}
                    options={lineChartDataOptions?.lineOptions}
                  />

                </div>
              </DataContainer>
            </div>
          )}
           {activeTab === "app_analytics" &&(
          <div className={`${(activeTab === "app_analytics") ? `col-2 pro-d-flex pro-flex-column pro-gap-4` : `col-6`}`}>
            {!isFireBasedDataFetching ? (
              <div className={` ${Style.card_col} ${(activeTab === "app_analytics" && chartDataNew?.data?.data?.labels?.length) ? `col-12 flex-fill` : `col-12`}`}>
                <DataContainer>
                  {fireBasedData?.data?.["active_users"] && (
                    <>
                      <h6 className={`pro-ttl h6 ${(activeTab === "app_analytics" && chartDataNew?.data?.data?.labels?.length) ? "pro-mb-5" : ""}`}>Active Users</h6>
                      <div
                        className={`${Style.media_count_status} ${(activeTab === "app_analytics" && chartDataNew?.data?.data?.labels?.length) ? Style.count_vertical : ""} pro-d-flex pro-items-center pro-gap-4`}
                      >
                        {fireBasedData?.data?.["active_users"]?.map(
                          (item, index) => (
                            <div
                              key={index}
                              className="col pro-d-flex pro-flex-column pro-justify-between"
                            >
                              <div className={Style.top_wrap}>
                                <OverlayTrigger
                                  placement="right"

                                  overlay={
                                    <BootstrapTooltip hidden={!item?.original_value} className="custom-tooltip">
                                      <strong>{item?.label}: </strong>
                                      <p>{item?.original_value}</p>
                                    </BootstrapTooltip>
                                  }
                                >
                                  <div>
                                    <span
                                      className={Style.inline_wrap}
                                    >
                                      <h3
                                        className={`${Style.count} pro-ttl h3 pro-mb-2 pro-fw-medium`}
                                      >
                                        {item.value}
                                      </h3>
                                    </span>
                                    <span className={Style.total}>{item.label}</span>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </DataContainer>
              </div>
            ) : (
              <div className={`${Style.card_col} ${Style.shimmer_wrapper} ${(activeTab === "app_analytics") ? `col-12 flex-fill` : `col-12`}`}>
                {
                  activeTab === "app_analytics" && (
                    <DashboardCardShimmer />
                  )
                }
              </div>
            )}
            {/* {activeTab === "app_analytics" && (
              <>
                {!isFetchingAppAnalyticsFirebaseData ? (
                  <div className={`col-12 pro-mt-auto flex-fill ${Style.card_col}`}>
                    <DashboardCard
                      data={appAnalyticsFirebaseData?.data}
                      title={appAnalyticsFirebaseData?.data?.label}
                      activeTab={activeTab}
                    />
                  </div>
                ) : (
                  <div className={`flex-fill ${Style.shimmer_wrapper} ${Style.card_col}`}>
                   <DashboardCardShimmer />
                  </div>
                )}
              </>
            )} */}


          </div>
)}
        </div>
      </div>
      {/* {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <> 
          <EmptyData />
        </>
      )} */}
      {activeTab === "app_analytics" && (
        <div className={Style.charts_container}>
          {!isFetching ? (
            <>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box} ${Style.box_lg} flex-fill`}>
                  <DataContainer>
                    <div className="pro-d-flex pro-h-100 pro-gap-6">
                      <div className={`${Style.line_chart_info} `}>
                        <h6 className="pro-ttl h6 mb-4">Streams</h6>
                        <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
                          {
                            dashboardData?.data?.streamsLineChart?.totals
                              ?.current
                          }
                        </h3>

                        <span
                          className={`${Style.growth_percentage} ${dashboardData?.data?.streamsLineChart?.totals?.growth && dashboardData?.data?.streamsLineChart?.totals?.growth?.includes('-') ?
                              Style.decrement : Style.increment
                            }   ${Style.increment} pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                        >
                          <span className={`material-symbols-outlined ${Style.arrow}`}>
                            arrow_outward
                          </span>
                          <span>
                            {
                              dashboardData?.data?.streamsLineChart?.totals?.growth
                            }
                          </span>
                        </span>
                      </div>
                      <div className={`${Style.line_chart_wrap} pro-d-flex`}>
                        <Charts
                          type="line"
                          labels={lineData?.labels}
                          datasets={lineData?.datasets}
                          options={lineData?.lineOptions}
                        />
                      </div>
                    </div>
                  </DataContainer>
                </div>
                <div className={`${Style.graph_box}`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Age Category</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={ageBarData?.labels}
                        datasets={ageBarData?.datasets
                        }
                        options={ageBarData?.ageBarDataOptions}
                      />
                    </div>
                  </DataContainer>
                </div>
                {/* <div className={`${Style.graph_box}`}>
                <DataContainer>
                  <div className="pro-w-100">
                    <TopPerformance dashboardData={dashboardData} />
                  </div>
                </DataContainer>
              </div> */}
              </div>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Top Countries</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={barData?.labels}
                        datasets={barData?.datasets}
                        options={barData?.barDataOptions}
                      />
                    </div>
                  </DataContainer>
                </div>

                <div className={`${Style.graph_box} ${Style.box_lg} `}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Gender</h6>
                    {/* <Charts
                  type="doughnut"
                  labels={doughnutData?.labels}
                  datasets={doughnutData?.datasets}
                  options={genderGraph}
                /> */}

                    {/* doughnutChartData,
                doughnutChartOptions,
                doughnutTextCenter, */}
                    {dashboardData?.data?.genderPieChart?.total_count ? (
                      <div className={Style.doughnut_chart_wrap}>
                        <Doughnut
                          data={doughnutData}
                          options={genderGraph}
                          plugins={[doughnutTextCenter]}
                        />
                      </div>
                    ) : (
                      <>
                        <EmptyData />
                      </>
                    )}
                  </DataContainer>
                </div>
              </div>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Top Regions</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={barDataCities?.labels}
                        datasets={barDataCities?.datasets}
                        options={barDataCities?.barDataCitiesOptions}
                      />
                    </div>
                  </DataContainer>
                </div>
              </div>
              <div className="row gx-5 pro-mb-5">
                <div className={`${Style.graph_box}  flex-fill`}>
                  <DataContainer>
                    <BarChart dashboardData={dashboardData} />
                  </DataContainer>
                </div>

                <div className={`${Style.graph_box} ${Style.box_lg}`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Devices</h6>
                    {/* <Charts
                  type="doughnut"
                  labels={deviceDoughnutData?.labels}
                  datasets={deviceDoughnutData?.datasets}
                  options={deviceDoughnutOption}
                /> */}
                    {dashboardData?.data?.devicesPieChart?.total_count ? (
                      <div className={Style.doughnut_chart_wrap}>
                        <Doughnut
                          data={deviceDoughnutData}
                          options={deviceDoughnutOption}
                          plugins={[semiDoughnutTextCenter]}
                        />
                      </div>
                    ) : (
                      <>
                        <EmptyData />
                      </>
                    )}
                  </DataContainer>
                </div>
              </div>
            </>
          ) : (
            <div className="app-loading fill"></div>
          )}
        </div>
      )}

      {activeTab === "content_analytics" && (
        <div className={`row row-cols gx-5 pro-mb-5 ${Style.tab_table_wrap}`}>
          {/* <TopRjList dashboardData={dashboardData?.data?.topCategories?.rjs} /> */}
          <div className="col-12 pro-mb-4">
            <TopProgramList
              dashboardData={dashboardData?.data?.programListData}
            />
          </div>
          <div className="col-12 pro-mb-4">
            <TopGenresList
              dashboardData={dashboardData?.data?.categoryListData}
            />
          </div>
        </div>
      )}
      {/* <div className="row row-cols-3 gx-5 pro-mb-5">
        <TopRjList dashboardData={dashboardData?.data?.topCategories?.rjs} />
        <TopProgramList
          dashboardData={dashboardData?.data?.topCategories?.programs}
        />
        <TopPlayedList
          dashboardData={dashboardData?.data?.topCategories?.played}
        />
      </div> */}
    </>
  );
};

export default HomeDashboardListing;
