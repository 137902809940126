import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteNewsCategoryDataMutation,
  useGetNewsCategoryListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/newscategory";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/NewsCategory/newscategorySlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { current } from "@reduxjs/toolkit";
import {
  newscategory as newscategoryQuery
} from "../../../store/queries/newscategory";

const useCategoryList = () => {
  const dispatch = useDispatch();
  const newscategoryState = useSelector((state) => state.newscategory);
  const { showCreateModal, isEditData, activeId, selectedUpdateRows } = useSelector((state) => state.newscategory);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [newscategoryDelete] = useDeleteNewsCategoryDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [filterShow, setFilterShow] = useState(false);
 

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: newscategory = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetNewsCategoryListDataQuery({
    length: newscategoryState.currentPageSize,
    page: newscategoryState.currentPage,
    search: newscategoryState.search,
    sort_by: newscategoryState.sortBy,
    sort_order: newscategoryState.sortOrder,
    status: newscategoryState.status,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetNewsCategoryListDataQuery(
  //     { skip: isEditData === false }
  //   );

  const hasCreatePermission = useMemo(() => {
    let permission = newscategory?.data?.original?.permissions?.create_permission
    return permission ?? false;
  }, [newscategory]);

  const hasEditPermission = useMemo(() => {
    let permission = newscategory?.data?.original?.permissions?.edit_permission
    return permission ?? false;
  }, [newscategory]);
  const hasViewPermission = useMemo(() => {
    let permission = newscategory?.data?.original?.permissions?.list_permission
    return permission ?? false;
  }, [newscategory]);
  const hasDeletePermission = useMemo(() => {
    let permission = newscategory?.data?.original?.permissions?.delete_permission
    return permission ?? false;
  }, [newscategory]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    image_url: { label: "Image", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    dispatch(
              updateConfig((state) => {
                state.currentPageSize = 10;
              })
            );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [newscategoryState.status]);


  const deleteNewsCategoryDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (newscategoryState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [newscategoryState.clearSelection]);

  const deleteNewsCategory = () => {
    newscategoryDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleSort = (label) => {
    if (newscategoryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = newscategoryState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = ""
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };
  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleCategoryDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0]
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = newscategory?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowImageModal(() => false);
    setImageData("");
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params);
    dispatch(
      newscategoryQuery.util.updateQueryData(
        "getNewsCategoryListData",
        {
          length: newscategoryState.currentPageSize,
          page: newscategoryState.currentPage,
          search: newscategoryState.search,
          sort_by: newscategoryState.sortBy,
          sort_order: newscategoryState.sortOrder,
          status: newscategoryState.status,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
          res?._id === data?._id
          ? {
            ...res,
            status:data?.status === 0 ? 1 : 0,
          }
          : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original:{
                ...updatedCachedData.data.original,
                data:newResult
              }
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild] ? `${data[feild]}`: "-"}</p>,
      image_url: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        status_label: (field, data) => {
          const isChecked = data?.status === 1;
          return (
            <>
            { hasEditPermission ? (
            <div className="form-check form-switch ">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={isChecked}
                onChange={(e) => handleToggle(e, data)}
              />
            </div>
            ): data?.status_label?.name}
            </>
          );
        },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    newscategory,
    activeId,
    newscategoryState,
    currentPage: newscategoryState.currentPage,
    activeFilter: newscategoryState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: newscategoryState.recordsTotal,
    showDeleteModal,
    // isUpdateDataSuccess,
    isEditData,
    imageData,
    showImageModal,
    filterShow,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    deleteNewsCategoryDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    handleCategoryDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteNewsCategory,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useCategoryList;
